import styled from "styled-components"
import React from "react"

const Blue = styled.section`
  background-color: #455260;
  height: 100%;
  width: 100%;
  color: #eff1ef;
  font-weight: 300;
  position: relative;
  display: inline-block;

  @media (min-width: 992px) {
    padding: 50px 90px;
  }

  @media (max-width: 991px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  & h2 {
    font-size: 24px;
    margin-top: 30px;
  }

  & p {
    font-size: 18px;
    max-width: 85ch;
    line-height: 1.5em;
  }

  @media (min-width: 992px) {
    & p,
    ul,
    h3 {
      padding-left: 90px;
    }
  }

  & h3 {
    font-size: 18px;
    font-weight: 900;
  }
`

const Row = styled.div`
  @media (max-width: 992px) {
    width: 85%;
    margin: auto;
  }
`

const BluePageContent = ({ children }) => {
  return (
    <Blue>
      <Row>{children}</Row>
    </Blue>
  )
}

export default BluePageContent
