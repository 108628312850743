import React from "react"
import Seo from "gatsby-plugin-wpgraphql-seo"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import BluePageContent from "../../components/StyledElements/bluePageContent"
import parse from "html-react-parser"

const WpPage = ({ data }) => {
  const { wpPage } = data

  return (
    <>
      <Seo post={wpPage} />
      <Layout>
        <BluePageContent>
          <h1>{wpPage.title}</h1>
          {parse(`${wpPage.content}`)}
        </BluePageContent>
      </Layout>
    </>
  )
}

export default WpPage

export const query = graphql`
  query GutenbergPageById($id: String) {
    wpPage(id: { eq: $id }) {
      id
      content
      title
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
    }
  }
`
